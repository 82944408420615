.container {
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
}

// @media screen and (min-width: 1200px) {
//   header {
//     .container {
//       height: 100px;
//     }
//   }
// }

@media screen and (max-width:1919px) and (min-width: 1200px) {
  .container {
    max-width: 1000px;
  }
  header {
    .container {
      height: 100px;
    }
  }
}

@media screen and (max-width:1199px) and (min-width: 884px) {
  .container {
    max-width: 764px;
  }
  header {
    .container {
      height: 70px;
    }
  }
}

@media screen and (max-width:883px) {
  .container {
    max-width: 320px;
  }
  header {
    .container { 
      height: 70px;
    }
  }
}


