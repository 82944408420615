/* reset */
body,p,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,th,td,form,fieldset,legend,input,textarea,button,select{margin:0;padding:0;background:none;-webkit-text-size-adjust:none;}
body,input,textarea,select,button{display:block;font-family : 'NanumSquare',sans-serif;font-size:12px;margin:0;padding:0;border:0}
img,fieldset{border:0; max-width: 100%;
  height: auto;display: block;}
ul,ol{list-style:none}
em,address{font-style:normal}
a{color: #181818;text-decoration:none;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);}
.blind{visibility:hidden;overflow:hidden;position:absolute;top:0;left:0;width:1px;height:1px;font-size:0;line-height:0}
input,textarea{-webkit-appearance: none;-moz-appearance: none;appearance: none;-webkit-border-radius: 0;border-radius: 0;outline: none;}
::-webkit-input-placeholder {color: #aeaeae;}/* 크롬 4?56 */
:-moz-placeholder {color: #aeaeae;}/* 파이어폭스 4?18 */
::-moz-placeholder {color: #aeaeae;}/* 파이어폭스 19?50 */
:-ms-input-placeholder {color: #aeaeae;}/* 인터넷 익스플로러 10+ */
::placeholder {color: #aeaeae;}/* 파이어폭스 51+, 크롬 57+ */
input::-ms-check{display:none}/*IE input*/
html{
  // scrollbar-3dLight-Color: #efefef;
  // scrollbar-arrow-color: #dfdfdf;
  // scrollbar-base-color: #efefef;
  // scrollbar-Face-Color: #dfdfdf;
  // scrollbar-Track-Color: #efefef;
  // scrollbar-DarkShadow-Color: #efefef;
  // scrollbar-Highlight-Color: #efefef;
  // scrollbar-Shadow-Color: #efefef;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
// ::-webkit-scrollbar {width:8px;height:8px;border:3px solid #f0f0f0;}
// ::-webkit-scrollbar-track{background:#efefef;-webkit-border-radius:10px;border-radius:10px;}  
// ::-webkit-scrollbar-thumb{height:50px;width:50px;background: #cdcdcd;-webkit-border-radius:8px;border-radius:8px;}
html::-webkit-scrollbar {
  // display: none;
  // scroll-behavior: smooth;
}
table{border-collapse: collapse;border-spacing: 0;width: 100%;}

button:focus {
  outline: none;
}

div {
  //-ms-user-select: none;
  //-moz-user-select: -moz-none;
  //-khtml-user-select: none;
  //-webkit-user-select: none;
  //user-select: none;
}
body { overflow-x: hidden; overflow-y: auto;}
figure {margin:0}
