@import './common/nomalize';
@import './common/typography';
@import './common/grid';


* {
    font-family: 'Noto Sans KR', sans-serif;
    font-style: normal;
    // letter-spacing: -1px;
}
body {
    overscroll-behavior-y: none;
}

html {
    overscroll-behavior-y: none;
    overscroll-behavior: contain;
}
button, p, li {
    font-family: 'Noto Sans KR', sans-serif;
    font-style: normal;
    // letter-spacing: -1px;
}

h2 {
    font-family: 'Roboto';
    font-style: normal;
}

